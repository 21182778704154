(async () => {
    // 1. Установка константы и виджет инсталл
    const COOKIE_NAME = 'MCN_CALLTRACKING';
    let WIDGET_INSTALL = false;

    const getUtmsParams = (start_with) => {
        // console.log('getParams')
        return location.search.substr(1)
            .split('&')
            .reduce(function (res, a) {
                const t = a.split('=');
                const vName = decodeURIComponent(t[0]);
                if (vName.startsWith(start_with)) {
                    res[vName] = t.length == 1 ? null : decodeURIComponent(t[1]);
                }
                return res;
            }, {});
    }

    const getWidgetData = async (token, domain) => {

        let url = `https://${domain}/daemon/api/public/api/site/get_widget?token=${token}`
        // Оставляем по умолчанию RU для старых виджетов без домена
        let widget_data = null;
        try {
            const result = await fetch(url);
            widget_data = (await result.json()).result.widget_data;
        } catch (e) {
            // console.log(e);
        }
        if (widget_data) {
            return widget_data;
        }
        return {};
    }

    function getCookieParams(name) {
        // console.log('getCookieParams')

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        let splitCookie = matches?.input?.split(';')

        let googleId = splitCookie?.filter((cookieElement) => {
            if (cookieElement?.includes('_ga')) {
                return cookieElement
            }
        })

        return googleId?.[0];
    }

    function getCookie(name) {

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name?.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
            // console.log(document.cookie,'matches')
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(variable, value, expires_seconds) {
        document.cookie = variable + "=" + value + "; max-age=" + expires_seconds + ";domain=" + location.hostname + ';path=/';
    }

    const phoneFormatter = (number, format) => {
        // console.log('Formatter')
        if (typeof number !== "string") {
            return '<???-??-??>';
        } else if (format == '3-digit-plus-bracket') {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
        } else if (format == '3-digit-plus') {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3-$4-$5");
        } else if (format == '3-digit-cut') {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "($2) $3-$4-$5");
        } else if (format == '3-digit-plus-ru') {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "8 $2 $3-$4-$5");
        } else if (format == '3-digit-plus-bracket-ru') {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "8 ($2) $3-$4-$5");
        } else if (format == '4-digit-plus-bracket') {
            return number.replace(/(\d{1})(\d{4})(\d{2})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
        } else if (format == '4-digit-plus') {
            return number.replace(/(\d{1})(\d{4})(\d{2})(\d{2})(\d{2})/, "+$1 $2 $3-$4-$5");
        } else if (format == '4-digit-cut') {
            return number.replace(/(\d{1})(\d{4})(\d{2})(\d{2})(\d{2})/, "($2) $3-$4-$5");
        } else if (format == '4-digit-plus-ru') {
            return number.replace(/(\d{1})(\d{4})(\d{2})(\d{2})(\d{2})/, "8 $2 $3-$4-$5");
        } else if (format == '4-digit-plus-bracket-ru') {
            return number.replace(/(\d{1})(\d{4})(\d{2})(\d{2})(\d{2})/, "8 ($2) $3-$4-$5");
        } else if (format == '2-digit-de') {
            return number.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{3})/, "49 $2 $3 $4 $5");
        } else if (format == '2-digit-plus-de') {
            return number.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{3})/, "+$1 $2 $3 $4 $5");
        } else if (format == '1-digit-hu') {
            return number.replace(/(\d{2})(\d{1})(\d{3})(\d{4})/, "36 $2 $3 $4");
        } else if (format == '1-digit-plus-hu') {
            return number.replace(/(\d{2})(\d{1})(\d{3})(\d{4})/, "+$1 $2 $3 $4");
        } else if (format == '1-digit-sk') {
            return number.replace(/(\d{3})(\d{1})(\d{3})(\d{2})(\d{3})/, "421 $2 $3 $4 $5");
        } else if (format == '1-digit-plus-sk') {
            return number.replace(/(\d{3})(\d{1})(\d{3})(\d{2})(\d{3})/, "+$1 $2 $3 $4 $5");
        } else if (format == '1-digit-at') {
            return number.replace(/(\d{2})(\d{1})(\d{3})(\d{4})/, "43 $2 $3 $4");
        } else if (format == '1-digit-plus-at') {
            return number.replace(/(\d{2})(\d{1})(\d{3})(\d{4})/, "+$1 $2 $3 $4");
        } else if (format == '8-digit-number') {
            return number.replace(/(\d{2})(\d{1})(\d{2})(\d{6})/,"+$4").replace(/(\d{2})(\d{2})(\d{2})/,"$1-$2-$3");
        }
        else {
            return number.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3-$4-$5");
        }
        return '<???-??-??>';
    }

    const showNumber = (phone, options) => {
        console.log(phone, options, 'showNumber');
        const { divName, isUseID, format, isOnlyChangeHref } = options;
        if (phone) {
            if (isUseID) {
                let el = document.getElementById(divName);
                if (el) {
                    if(!isOnlyChangeHref){
                        el.innerHTML = phoneFormatter(phone, format);
                    }

                    if (phone.split('')[0] === '8' || phone.split('')[0] === '+') {
                        el.setAttribute("href", `tel:${phone}`)
                    } else {
                        el.setAttribute("href", `tel:${phoneFormatter(phone).replace(/\s/g, '')}`);
                    }
                }
            } else {
                for (const element of document.getElementsByClassName(divName)) {

                    if(!isOnlyChangeHref){
                        element.innerHTML = phoneFormatter(phone, format)
                    }
                 
                    if (phone.split('')[0] === '8' || phone.split('')[0] === '+') {
                        element.setAttribute("href", `tel:${phone}`)
                    } else {
                        element.setAttribute("href", `tel:${phoneFormatter(phone).replace(/\s/g, '')}`);
                    }
                }
            }
        }
    }

    const setNewCookie = (visitResponse, token = 'rent') => {

        const { rent_duration = 30, number } = visitResponse;
        if (number) {
            // console.log(rent_duration, 'Длительность, установка кук')
            setCookie(token, number, rent_duration)
        }
        if (!number) {
            // console.log('Устанавливаем нулевое значение')
            setCookie(token, 0, 3600)
        }
    }

    const setParamsForCachedNumber = (visitResponse, options) => {
        const { rent_duration = 30, number } = visitResponse;
        if (number) {
            // console.log(rent_duration, 'Длительность, установка параметров')
            setCookie('mcn_params', JSON.stringify(options), rent_duration)
        }
        if (!number) {
            // console.log('Установка по умолчанию')
            setCookie('mcn_params', JSON.stringify(options), 3600)
        }
    }

    const setFirstNumber = (visitResponse, options) => {
        if (visitResponse) {
            const { number, timeLeft, cookie } = visitResponse;
            setParamsForCachedNumber(visitResponse, options)
            setNewCookie(visitResponse, options?.token)
            showNumber(number, options);
            //Устанавливаем номер в следующий по списку контейнер
            if(options?.class1 || options?.id1){
                showNumber(number, {divName:options.divName1, isUseID:options.isUseID1, format:options.format1, isOnlyChangeHref:options?.isOnlyChangeHref1});
            }
         
            if (cookie)
                setCookie(COOKIE_NAME, cookie, timeLeft);
        }
    }

    const setCachedNumber = (isNumber, params) => {
        // 5 Вызывается конструктор классов
        // const isNumber = getCookie(token); // GetNumberByToken nameCookie = number

        if (isNumber && isNumber !== '0') {
            showNumber(isNumber, params)
            if(params?.id1 || params?.class1){
                showNumber(isNumber, {divName:params.divName1, isUseID:params.isUseID1, format:params.format1, isOnlyChangeHref:params?.isOnlyChangeHref1});
            }
            console.log('Установили номер из кэша')
            return true
        }
        if (isNumber === '0') {
            console.log('Посещение уже было')
            return true
        }
        console.log('Not number')
        return false
    }

    const checkParamsAndNumber = (token) => {
        const isNumber = getCookie(token);
        const params = getCookie('mcn_params');

        if (isNumber && params) {
            return { isNumber, params: JSON?.parse(params) }
        }
        return false
    }

    async function setMetrica(clientID, params, visitResponseRent) {
        let visitResponse = await fetch(`https://${params.domain}/visits/api/public/api/update_metrica`, {
            method: 'post', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({ya_client_id:clientID,token:params.token, rent_id:visitResponseRent?.rent_id})
        });
    }

    class MCNTrackerWidget {
        // 6 Вызываем метод setup 
        constructor(static_options, isMcnTest) {
            this.setup(static_options, isMcnTest);
        }

        async getMetrics(params, visitResponseRent) {

            let tryCounter = 0;

            function getYandexClientId (){
                if(tryCounter < 10){
                    if (params.ya_tracker_id && typeof ym === "function") {
                        console.log("Yandex tracker id = true", params.ya_tracker_id);
                        ym(params.ya_tracker_id, 'getClientID', (function (clientID) {
                            console.log(`ya_tracker_client_id=${clientID}`);
                            if(clientID){
                                 console.log(clientID,'ya_client_id')
                                 setMetrica(clientID, params, visitResponseRent)
                                 //отправляем 
                                 clearInterval(timer)                              
                            }else{
                                tryCounter + 1
                            }
                        }).bind(this));
                    } else {
                        console.log("Yandex tracker id = false");
                        tryCounter + 1
                    }
                }else{
                    clearInterval(timer)
                }
            }
            const timer = setInterval(getYandexClientId, 6000);
        }

        async setup(static_options, isMcnTest) {
            // console.log(static_options, 'setup')
            let { token, domain = 'calltracking.mcn.ru' } = static_options;
            // Если есть параметры и номер, устанавливаем из кэша
            const isParams = checkParamsAndNumber(token);
            if (isParams && !isMcnTest) {
                const { isNumber, params } = isParams;
                const isSetChachedNumber = setCachedNumber(isNumber, params);
                // console.log(isSetChachedNumber, 'Установлен номер из кэша')
                // Убедится вышли ли мы из виджета
                return false;
            }
            // В static_options параметры передаются из старого виджета, теперь берем их из запроса 
            const site_widget_data = await getWidgetData(token, domain);
  
            let className = site_widget_data?.class || static_options?.class;
            let className1 = site_widget_data?.class1 || static_options?.class1;
            let idName = site_widget_data?.id || static_options?.id;
            let idName1 = site_widget_data?.id1 || static_options?.id1;
            let format = site_widget_data?.format || static_options?.format;
            let format1 = site_widget_data?.format1 || static_options?.format1;
            let ya_tracker_id = site_widget_data?.ya_tracker_id || static_options?.ya_tracker_id;
            let google_tracker_id = site_widget_data?.google_tracker_id; // new params
            let isUseID = idName?.length > 0;
            let isUseID1 = idName1?.length > 0;
            let isOnlyChangeHref = site_widget_data?.isOnlyChangeHref || false;
            let isOnlyChangeHref1 =site_widget_data?.isOnlyChangeHref1 || false;

            let divName = (idName?.length > 0) ? idName : className;
            let divName1 = (idName1?.length > 0) ? idName1 : className1;

            let params = { token, domain, isMcnTest, class: className, 
                class1:className1, id1:idName1, id: idName, format, 
                format1, ya_tracker_id, isUseID, isUseID1, divName, divName1, ya_client_id: '', google_tracker_id, isOnlyChangeHref, isOnlyChangeHref1 };
            console.log("wdg init", params);
            // Устанавливаем номер из кэша, если он есть, если нет, продолжаем получение номера

            if (ya_tracker_id && typeof ym === "function") {
                console.log("use_ya_tracker=1");
                ym(ya_tracker_id, 'getClientID', (function (clientID) {
                    console.log(`ya_tracker_client_id=${clientID}`);
                    ya_tracker_id = clientID;
                    this.install(params);
                }).bind(this));
                console.log(ym, "ya_tracker started");
                setTimeout(() => this.install(params), 500); // На случай, если метрика не вызовет свой callback
            } else {
                console.log("use_ya_tracker=0");
                this.install(params);
            }
        }

        async install(params) {

            if (!WIDGET_INSTALL) {
                WIDGET_INSTALL = true;
            } else {
                console.log("WIDGET ALREADY INSTALLED");
                return;
            }
            console.log("wdg install", params);

            const getReferrer = () => {
                const urlRefParams = getUtmsParams('referrer')?.referrer || [];
                const documentRef = document?.referrer;
                if (urlRefParams?.length > 0) {
                    return urlRefParams
                }
                return documentRef
            }

            const { token, domain, ya_tracker_id, ya_client_id, isMcnTest, google_tracker_id } = params;
            const send = {
                location: document?.location?.href,
                referrer: getReferrer(),
                token,
                ya_tracker_id,
                ya_client_id,
                roistat_visit_id: window?.roistat?.visit || false,
                utms: getUtmsParams('utm'),
                wdg: getUtmsParams('wdg'),
                cookie: getCookie(COOKIE_NAME),
                google_tracker_id,
                google_id: getCookieParams('_ga')?.substring(5),
                user_agent: window?.navigator?.userAgent || 'not agent',
                mcn_params: { isMcnTest }
            };
            // Оставляем по умолчанию RU для старых виджетов без домена
            let visitResponse = await fetch(`https://${domain}/visits/api/public/api/visit`, {
                method: 'post', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(send)
            });
            const visitResponseRent = await visitResponse.json();
            // console.log(`widget set up visitResponse:${JSON.stringify(visitResponseRent)} WidgetParams:${JSON.stringify(params)}`)
            const number = visitResponseRent?.number;
            const timeLeft = visitResponseRent?.timeLeft;
            const rent_id = visitResponseRent?.rent_id;

            setFirstNumber(visitResponseRent, params);
            if(number && timeLeft && timeLeft != 10 && params.token && rent_id){
                await this.getMetrics(params, visitResponseRent);
            }
        }
    }

    //  2 Инициализация
    let mcnTrackerWidget = undefined;

    const bots = ["Googlebot", "+http://yandex.com/bots"];

    const checkIsBot = (bots) => {
        // GLOBAL
        const userAgent = window?.navigator?.userAgent;
        var isBool = bots.some(botName => userAgent?.includes(botName));
        return isBool;
    }

    async function prepareWidget(static_options) {

        // let location = document?.location?.href;
        // let userAgent = window?.navigator?.userAgent;

        // await fetch(`https://${static_options?.domain}/visits/api/public/api/logger`, {
        //     method: 'post', headers: {
        //         'Content-Type': 'application/json'
        //     }, body: JSON.stringify({static_options,location,userAgent})
        // });

        const isMcnTest = Object.values(getUtmsParams('mcn'))?.length > 0;
        const isBot = checkIsBot(bots);
        if (isBot) {
            console.log('This is bot')
            return false;
        }

        mcnTrackerWidget = new MCNTrackerWidget(static_options, isMcnTest);
        return mcnTrackerWidget;
    }
    // 3 Добавляем свойство для window
    window.mcnTrackerWidget = {
        initWidget: (static_options) => document.addEventListener("DOMContentLoaded", prepareWidget(static_options))
    };

})();

// getCookie,
// deleteCookie: () => setCookie(COOKIE_NAME, '', 0),
// debugWidget: function (opt) {
//     console.log('debugWidget')
//     prepareWidget(opt).install(opt);
// },
// const deleteCookie = (name) => { document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;" + ";domain=" + location.hostname + "path=/"; }

// const normal = (value) => typeof value === "string" ? value.trim().toLowerCase() : '';